.success-container {
  min-height: 100vh;
  background-color: #f9fafb;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.success-content {
  max-width: 400px;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  text-align: center;
}

.icon-container {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
}

.icon {
  font-size: 3rem;
  color: #22c55e; /* Green color */
}

.success-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #111827;
  margin-bottom: 0.5rem;
}

.success-message {
  color: #6b7280;
}
