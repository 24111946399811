@font-face {
  font-family: "Noto Sans";
  src: url("https://fonts.googleapis.com/css?family=Noto+Sans:400,400i,700,700i");
}
a:link {
  color: #c7a338;
}
body {
  padding: 0;
  background: #e4e4e4;
}
.main-container {
  width: 700px;
  margin: auto;
  background: white;
  border-top: 7px #02adc1 solid;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.35);
}
.main-container .content {
  margin: 40px 49px 30px 48px;
}
.main-container .content h1 {
  font-family: "NotoSans", helvetica, arial, sans-serif;
  font-size: 27px;
  color: #222;
  text-align: left;
  line-height: 22px;
  letter-spacing: -0.01em;
}
.main-container .content p {
  font-family: "NotoSans", helvetica, arial, sans-serif;
  font-size: 17px;
  color: #666;
  text-align: left;
  line-height: 25px;
}
.main-container .footer {
  position: relative;
  height: 45px;
}
.main-container .footer img {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.checkbox-style {
  width: 12px;
  height: 12px;
  background: #b2b2b2;
}
.mobile {
  display: block;
}
input[type="submit"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #c7a338;
  width: 172px;
  height: 48px;
  font-family: "Noto Sans";
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  border: none;
  color: #232323;
  padding: 12px 12px 12px 6px;
  text-decoration: none;
  cursor: pointer;
  margin-top: 30px;
}

a:visited {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .mobile {
    display: inline;
  }
}
select {
  background: #b2b2b2;
  border: none;
  font-family: "Noto Sans", Arial, sans-serif;
  font-size: 14px;
  color: #232323;
  padding: 8px;
  width: 300px;
  cursor: pointer;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.preference-container {
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 2rem;
}

.preference-content {
  max-width: 600px;
  margin: 0 auto;
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h1 {
  color: #333;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
}

.email-info {
  color: #666;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eee;
}

.preference-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.preferences-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.preference-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.5rem;
  border-radius: 4px;
  transition: background-color 0.2s;
  cursor: pointer;
  font-size: 1.1rem;
}

.preference-item:hover {
  background-color: #f8f8f8;
}

.preference-checkbox {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.unsubscribe-reason {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.reason-select {
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  font-size: 1rem;
}

.update-button {
  background-color: #6c63ff;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.update-button:hover:not(:disabled) {
  background-color: #5a54d6;
}

.update-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.spinner {
  width: 16px;
  height: 16px;
  border: 2px solid #ffffff;
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@media (max-width: 768px) {
  .preference-container {
    padding: 1rem;
  }

  .preference-content {
    padding: 1.5rem;
  }

  h1 {
    font-size: 1.5rem;
  }
}
